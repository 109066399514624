import React from 'react'
import Leaflet from 'leaflet'
import { EditControl } from 'react-leaflet-draw'

import {
  Map,
  Marker,
  TileLayer,
  CircleMarker,
  Popup,
  FeatureGroup
} from 'react-leaflet'

import '../../../node_modules/leaflet/dist/leaflet.css'
import '../../../node_modules/leaflet-draw/dist/leaflet.draw.css'
import marker from '../../assets/marker-icon.png'
import markerShaddow from '../../assets/marker-shadow.png'

import { MapContainer, MapSingleContainer } from './styled'

delete Leaflet.Icon.Default.prototype._getIconUrl
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: marker,
  iconUrl: marker,
  shadowUrl: markerShaddow
})

export default function SingleMap(props) {
  let mapProps = {
    zoom: 7,
    center: [39.6549602, -8.5984025]
  }

  if (props.location) {
    mapProps.center = props.location
  }
  return (
    <MapSingleContainer>
      <MapContainer>
        <Map className={'map-container'} maxZoom={23} {...mapProps}>
          <TileLayer
            maxZoom={23}
            maxNativeZoom={18}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* <SearchLayer updateLocation={props.updateLatLon} /> */}
          {/** We can edit the marker */}
          {props.updateLatLon && (
            <FeatureGroup>
              <EditControl
                key={`${new Date()}-elements`}
                position="topleft"
                onEdited={e => {
                  const layer = e.layers._layers
                  Object.keys(layer).map(layerI => {
                    if (!layer[layerI]) return null
                    const centerM = layer[layerI]._latlng
                    //we need to update element view
                    props.updateLatLon(centerM.lat, centerM.lng)
                    return layerI
                  })
                }}
                onCreated={e => {
                  // const geoJson = e.layer.toGeoJSON();
                  // const center = geoJson.geometry.coordinates;
                  // console.log(center);
                  // props.updateLatLon(center[0], center[1]);
                  // Now we need to remove it
                  // it will be added from the db later
                  // const { edit } = refs;
                  // edit.context.layerContainer.removeLayer(e.layer._leaflet_id);
                }}
                edit={{
                  marker: true,
                  circle: false,
                  circlemarker: false,
                  rectangle: false,
                  polygon: false,
                  polyline: false
                }}
                draw={{
                  marker: false,
                  circle: false,
                  circlemarker: false,
                  rectangle: false,
                  polygon: false,
                  polyline: false
                }}
              />
              <Marker position={mapProps.center} />
            </FeatureGroup>
          )}
          {/** We are just showing the marker */}
          {!props.updateLatLon && !props.center && mapProps.center && (
            <CircleMarker center={mapProps.center} />
          )}
          {props.center &&
            props.center.map(
              (marker, i) =>
                marker.center && (
                  <CircleMarker
                    key={i}
                    center={marker.center}
                    radius={10}
                    fillColor={marker.color || '#5fba7d'}
                    color={marker.color || '#5fba7d'}
                    weight={3}
                    opacity={1}
                    fillOpacity={0.5}
                    riseOnHover={true}
                    autoPan={true}
                    keepInView={true}
                  >
                    <Popup>
                      <h6>{marker.name}</h6>
                      <p
                        style={{ margin: 0, padding: 0 }}
                        dangerouslySetInnerHTML={{ __html: marker.description }}
                      />
                    </Popup>
                  </CircleMarker>
                )
            )}
        </Map>
      </MapContainer>
    </MapSingleContainer>
  )
}
