import React, { useState, useEffect, useMemo, useRef } from 'react'
import dayjs from 'dayjs'
import { setRemoteCall } from '../../remote'
import { useGlobalState } from '../../store'
import ProjectComponent from './ProjectComponent'
import Person from './Person'
import {
  ProjectTitle,
  ProjectColor,
  Project,
  Resources,
  ResourceList,
  Weekday,
  ColummnTitle,
  WeekdayWrap,
  TotalResources
} from './styled'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

const WeekdayComponent = ({
  i,
  column,
  pickerSource,
  viewOptions,
  getSceneProjectsPayload,
  projectsPayloadMap,
  getSceneChildResourcesPayload,
  getSortedGroups,
  onClick,
  onResourceCardDrop,
  users,
  setDayResources,
  dayResources
}) => {
  const [projectTypes, setProjectTypes] = useGlobalState('projectTypes')
  const [userTypes, setUserTypes] = useGlobalState('userTypes')
  const [projects, setProjects] = useGlobalState('projects')
  const [resourceTypes, setResourceTypes] = useGlobalState('resourceTypes')

  const unavailable = useRef(0)
  const unolacated = useRef(0)

  let projectResourcesObj = {}
  let allocatedUsers = []
  let unavailableUsers = []
  let unallocatedUsers = []
  const projectsPayload = useMemo(() => {
    let prjPayl = projectsPayloadMap.map(prjPl => {
      const thisResources = getSceneChildResourcesPayload(
        prjPl.id,
        null,
        column.id
      )
      projectResourcesObj[prjPl.id] = thisResources
      allocatedUsers = [
        ...allocatedUsers,
        ...(projectResourcesObj[prjPl.id].filter(
          res => res.cardType === 'users'
        ) || [])
      ]
      return prjPl
    })

    if (
      viewOptions.displayType === 'day' &&
      JSON.stringify(dayResources) !== JSON.stringify(allocatedUsers)
    ) {
      setDayResources(allocatedUsers)
    }

    return prjPayl
  }, [projectsPayloadMap])

  // useEffect(() => {
  //   //hacked set state
  //   if (
  //     viewOptions.displayType === 'day' &&
  //     dayResources.length < 1 &&
  //     allocatedUsers.length > 0
  //   ) {
  //     setDayResources(allocatedUsers)
  //   }
  // }, [viewOptions, dayResources, projectsPayloadMap])

  /**
   * get unalocated users
   */
  const getUnalocatedUsers = useMemo(() => {
    return users
      .filter(user => {
        return !allocatedUsers.find(allocatedUser => {
          return allocatedUser._id === user._id
        })
      })
      .map(user => {
        let unavailType = ''
        //check if user is available
        if (user.availability && Array.isArray(user.availability)) {
          user.availability.map(avail => {
            let dateStart = avail[0]
            let dateEnd = avail[1]
            let availType = avail[2]
            if (
              dayjs(column.date, 'DD/MM/YYYY').isBetween(
                dayjs(dateStart).format('YYYY-MM-DD'),
                dayjs(dateEnd).format('YYYY-MM-DD'),
                'day',
                '[]'
              )
            ) {
              unavailType = availType
              unavailableUsers.push(user)
            }
          })
        }
        if (unavailType === '') {
          unallocatedUsers.push(user)
        }
        return { ...user, unavailType }
      })
  }, [projectsPayloadMap])
  return (
    <Weekday key={i} size={viewOptions.displayType}>
      <ColummnTitle color={'#474f58'}>
        <TotalResources>{unallocatedUsers.length}</TotalResources>
        {column.name} <span>{column.date}</span>{' '}
      </ColummnTitle>

      <WeekdayWrap {...column.props} size={viewOptions.displayType}>
        {unavailableUsers && (
          <ProjectColor className={'animated fadeIn'}>
            <ProjectTitle
              cursor={'pointer'}
              color={'#ccc'}
              style={{
                color: '#676a7a'
              }}
              onClick={() => {
                if (unavailable.current.style.display === 'none') {
                  unavailable.current.style.display = 'block'
                } else {
                  unavailable.current.style.display = 'none'
                }
              }}
            >
              <span
                style={{
                  background: ''
                }}
              >
                {unavailableUsers.length}
              </span>{' '}
              {'Ausências'}
              <span className={'open'}></span>
            </ProjectTitle>

            <Project>
              <div
                style={{ maxHeight: '25vh', overflow: 'auto', display: 'none' }}
                ref={unavailable}
              >
                <Resources>
                  <ResourceList>
                    {unavailableUsers.map((user, i) => {
                      // return <div key={i}>{user.firstName}</div>
                      return (
                        <Person
                          disabledrag={true}
                          key={i}
                          onClick={onClick}
                          // viewType={viewOptions.viewType}
                          data={user}
                          types={userTypes}
                        />
                      )
                    })}
                  </ResourceList>
                </Resources>
              </div>
            </Project>
          </ProjectColor>
        )}
        {/* <ProjectColor className={'animated fadeIn'}>
          <ProjectTitle
            cursor={'pointer'}
            color={'#ccc'}
            style={{
              color: '#676a7a'
            }}
            // onClick={() => setUnolocatedCollapse(!toggleUnolocatedCollapse)}
            onClick={() => {
              if (unolacated.current.style.display === 'none') {
                unolacated.current.style.display = 'block'
              } else {
                unolacated.current.style.display = 'none'
              }
            }}
          >
            <span
              style={{
                background: ''
              }}
            >
              {unallocatedUsers.length}
            </span>{' '}
            {'Não alocado'}
            <span className={'open'}></span>
          </ProjectTitle>

          <Project>
            <div
              style={{ maxHeight: '25vh', overflow: 'auto', display: 'none' }}
              ref={unolacated}
            >
              <Resources>
                <ResourceList>
                  {unallocatedUsers.map((user, i) => {
                    // return <div key={i}>{user.firstName}</div>
                    return (
                      <Person
                        disabledrag={true}
                        key={i}
                        onClick={onClick}
                        // viewType={viewOptions.viewType}
                        data={user}
                        types={userTypes}
                      />
                    )
                  })}
                </ResourceList>
              </Resources>
            </div>
          </Project>
        </ProjectColor> */}
        {projectsPayload.map((project, i) => {
          if (!project) return null

          // now we need to get the sorted grouped data
          const {
            usedProjectGroups,
            projectResourcesGroup,
            sortedGroups,
            sortedProjectResources
          } = getSortedGroups(projectResourcesObj[project.id] || [])

          const type = projectTypes
            ? projectTypes.find(t => t._id === project.t)
            : {}

          if (viewOptions.search) {
            const regex = new RegExp(
              viewOptions.search.toLowerCase().replace(' ', '(.*?)'),
              'g'
            )

            if (
              !regex.test(project.name.toLowerCase()) &&
              !regex.test(project.code.toLowerCase())
            ) {
              return null
            }
          }
          return (
            <ProjectComponent
              key={i}
              column={column}
              pickerSource={pickerSource}
              viewOptions={viewOptions}
              onClick={onClick}
              onResourceCardDrop={onResourceCardDrop}
              type={type}
              resourceTypes={resourceTypes}
              project={project}
              sortedProjectResources={sortedProjectResources}
              sortedGroups={sortedGroups}
              projectResources={projectResourcesObj[project.id]}
              usedProjectGroups={usedProjectGroups}
              projectResourcesGroup={projectResourcesGroup}
            />
          )
        })}
      </WeekdayWrap>
    </Weekday>
  )
}

export default WeekdayComponent
