import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { configAxios } from './util'
import { initNotification } from './notifications'
import 'react-toastify/dist/ReactToastify.css'
import 'react-responsive-modal/styles.css'
import 'simple-line-icons/dist/styles/simple-line-icons.css'
import PWAUpdate from './PWAUpdate'

import App from './app/App'
import * as serviceWorker from './serviceWorker'
configAxios()
initNotification()

ReactDOM.render(
  <PWAUpdate>
    <App />
  </PWAUpdate>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
