import React, { useEffect, useState } from 'react'
import { getGlobalState, useGlobalState } from '../../store'
import dayjs from 'dayjs'
import { setRemoteCall } from '../../remote'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/core'
import { Text } from '@chakra-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Paper from '@material-ui/core/Paper'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  }
}))

export default function CustomizedTimeline(props) {
  const [deleteId, setDeleteId] = useState(null)
  const projects = getGlobalState('projects')
  const users = getGlobalState('users')
  const resources = getGlobalState('resources')
  const [userHistory, setUserHistory] = useGlobalState('userHistory')
  const [projectHistory, setProjectHistory] = useGlobalState('projectHistory')
  const [resourceHistory, setResourceHistory] = useGlobalState(
    'resourceHistory'
  )

  const classes = useStyles()
  let data = []
  let type = props.type

  /**
   * get the history
   */
  useEffect(() => {
    if (type === 'user') {
      setRemoteCall('getUserHistory', props.id)
    } else if (type === 'project') {
      setRemoteCall('getProjectHistory', props.id)
    } else if (type === 'resource') {
      setRemoteCall('getResourceHistory', props.id)
    }
    // Run! Like go get some data from an API.
  }, [])

  //lets find the correct displya data type
  if (type === 'user') {
    data = userHistory
  } else if (type === 'project') {
    data = projectHistory
  } else if (type === 'resource') {
    data = resourceHistory
  }

  return (
    <Timeline align="alternate">
      {data &&
        data
          .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
          .map((d, i) => {
            let thisTitle = ''

            if (type === 'user') {
              const thisElement = projects.find(p =>
                d.projectId.includes(p._id)
              )
              thisTitle = thisElement && thisElement.name
            } else if (type === 'resource') {
              const thisElement = projects.find(p =>
                d.projectId.includes(p._id)
              )
              thisTitle = thisElement && thisElement.name
            } else if (type === 'project') {
              if (d.accountId && d.accountId.length > 0) {
                const thisElement = users.find(p => d.accountId.includes(p._id))
                thisTitle = thisElement
                  ? `${thisElement.firstName} ${thisElement.lastName}`
                  : ''
              }
              if (d.resourceId && d.resourceId.length > 0) {
                const thisElement = resources.find(p =>
                  d.resourceId.includes(p._id)
                )
                thisTitle = thisElement && thisElement.name
              }
            }

            return (
              <TimelineItem key={i}>
                <TimelineOppositeContent>
                  <Text fontSize={'.7em'} color={'#999'}>
                    {dayjs(d.endDate).format('DD/MM/YYYY')} <br />
                    {dayjs(d.startDate).format('DD/MM/YYYY')}
                  </Text>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Text fontSize={'1em'} fontWeight={'bold'}>
                      {thisTitle}
                    </Text>
                    {type === 'user' && (
                      <Text
                        fontSize={'.8em'}
                        cursor={'pointer'}
                        color={'#bc1111'}
                        onClick={() => setDeleteId(d._id)}
                      >
                        Remove
                      </Text>
                    )}
                    {/* <Text fontSize={'.7em'}>Because you need strength</Text> */}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          })}

      <AlertDialog
        motionPreset="slideInBottom"
        onClose={() => setDeleteId(null)}
        isOpen={deleteId}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Delete relation?</AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogFooter>
            <Button onClick={() => setDeleteId(null)}>No</Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                if (type === 'user') {
                  setRemoteCall('delRelation', { _id: deleteId })
                  setRemoteCall('getUserHistory', props.id)
                }
              }}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Timeline>
  )
}
