import React, { useState } from 'react'
import './Login.css'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Button, Flex, Input } from '@chakra-ui/core'
import { setGlobalState } from '../store'

function Reset(props) {
  const [password, setPassword] = useState('')
  setGlobalState('pageClass', 'login')
  // getGlobalState()

  const resetPassword = async () => {
    const token = props.match.params.token
    try {
      const response = await axios.post(`/auth/reset/${token}`, {
        password
      })
      if (response.data && response.data.msg) {
        props.history.push('/')
      } else {
        toast(`O token é invalido, verifique o link no seu email novamente`)
      }
    } catch (e) {
      console.log(e)
      toast(`O token é invalido, verifique o link no seu email novamente`)
      setGlobalState('loggedIn', null)
    }
  }

  const handleFormFieldChange = (key, { target: { value } }) => {
    setPassword(value)
  }

  return (
    <Flex
      align="center"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100vh"
    >
      <Box
        maxW="md"
        bg="white"
        width={'40%'}
        padding={'2em'}
        shadow="md"
        borderWidth="1px"
        rounded="lg"
        overflow="hidden"
      >
        <div className={'login-container'}>
          <div>
            <h1>Reset Password</h1>
            <form className="login-form" data-login-form>
              <Input
                type="password"
                data-login-password
                onChange={event => handleFormFieldChange('password', event)}
                placeholder="New Password"
              />

              <Button
                onClick={e => resetPassword(e)}
                variantColor="teal"
                value="Submit!"
                width={'100%'}
              >
                Set new password!
              </Button>
            </form>
          </div>
        </div>
      </Box>
    </Flex>
  )
}

export { Reset }
export default withRouter(Reset)
