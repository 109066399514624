import React, { useState } from 'react'
import Header from '../components/header'
import Edit from './Edit'
import { Grid, Box, Button } from '@chakra-ui/core'
import { useGlobalState, setGlobalState } from '../store'
import { setRemoteCall } from '../remote'
import TableProvider from '../components/Table/Table'
import Map from '../components/Map/Map'

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: '_id', l: '_id' },
  { k: 'image', l: 'image', t: 'upload' },
  { k: 'name', l: 'Nome' },
  { k: 'code', l: 'Código' },
  { k: 'type', l: 'Tipo' },
  { k: 'details', l: 'Detalhes', t: 'textarea' },
  { k: 'files', l: 'files', t: 'files' },
  { k: 'status', l: 'Estado' },
  { k: 'limits', l: 'Limite', t: 'limits' },
  { k: 'brand', l: 'Marca' },
  { k: 'model', l: 'Modelo' },
  { k: 'serialNumber', l: 'Numero de série' },
  { k: 'location', l: 'Localização', t: 'map' },
  { k: 'startDate', l: 'Data de Inicio', t: 'datetime' },
  { k: 'lastUpdate', l: 'Ultima Atualização', t: 'datetime' }
  // { k: 'attr', l: 'Atributos' },
]
export const typesCollumns = [
  { k: '_id', l: '_id' },
  { k: 'name', l: 'Nome' },
  { k: 'priority', l: 'Prioridade', t: 'number' },
  { k: 'details', l: 'Detalhes', t: 'textarea' },
  { k: 'color', l: 'Cor', t: 'color' },
  { k: 'status', l: 'Estado' }
  // { k: 'attr', l: 'Atributos' },
]

export default function Resources(props) {
  /**
   * Get Data
   */
  const [openModal, setOpenModal] = useState(false)
  const [view, setView] = useState('resources')
  const [data, setData] = useGlobalState('resources')
  const [types, setTypes] = useGlobalState('resourceTypes')
  //set the initical class name
  setGlobalState('pageClass', 'resources')

  const collumns = view === 'resources' ? tableCollumns : typesCollumns

  /**
   * Table data
   */
  const resources = view === 'resources' ? data : types

  /**
   * Table options
   */
  const options = {
    filter: true,
    filterType: 'dropdown',
    count: resources ? resources.length : 0,
    downloadOptions: { filename: 'report.csv', separator: ';' },
    tableBodyHeight: 'calc( 100vh - 260px )',

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const selectedIds = selectedRows.data.map(
        sel => resources[sel.dataIndex]._id
      )

      const actionType =
        view === 'resources' ? 'delResources' : 'delResourceTypes'
      const actionMessage =
        view === 'resources' ? ` Deleted Resources` : ` Deleted resources types`

      return (
        <div className="table-actions">
          {/* <Button
            variantColor="red"
            onClick={() =>
              setRemoteCall(actionType, selectedIds, actionMessage)
            }
          >
            Delete Selected
          </Button> */}
        </div>
      )
    },
    onRowClick: (rowData, rowMeta) => {
      setOpenModal(resources[rowMeta.dataIndex])
    }
  }

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall(
      view === 'resources' ? 'setResources' : 'setResourceTypes',
      data,
      notice
    )
  }

  return (
    <div className={'table-wrapper'}>
      <Box w="70%" className={'content'} padding={'0 1em'}>
        {' '}
        <Header>
          {/* <button onClick={() => props.socket.emit('page', 'games')}>
          Isto é o que está a acontecer hoje
        </button> */}
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Box w="100%">
              <h1>Recursos</h1>
            </Box>

            <Box w="100%" h="10">
              {view === 'resources' && (
                <Button
                  active={props.periodType === 'week'}
                  width={'50%'}
                  onClick={() => setView('types')}
                >
                  <span className={`text`}>
                    {' '}
                    <span className={`icon-book-open icons`} /> Tipos
                  </span>
                </Button>
              )}
              {view === 'types' && (
                <Button
                  active={props.periodType === 'week'}
                  width={'50%'}
                  onClick={() => setView('resources')}
                >
                  <span className={`text`}>
                    <span className={`icon-wrench icons`} /> Recursos
                  </span>
                </Button>
              )}
              <Button
                active={props.periodType === 'week'}
                width={'49%'}
                marginLeft={'1%'}
                variantColor="green"
                onClick={() => setOpenModal({})}
              >
                <span className={`text`}>
                  <span className={`icon-plus icons`} /> {'Adicionar'}
                </span>
              </Button>
            </Box>
          </Grid>
        </Header>
        {resources && (
          <div className={'contentTableMap'}>
            <div className={'tableWrapper'}>
              <TableProvider
                data={resources}
                columns={collumns.map(key => {
                  if (key.k === 'status') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          switch (Number(value)) {
                            case 0:
                              return 'Desativo'
                            case 1:
                              return 'Ativo'
                            case 2:
                              return 'Indisponível'
                            default:
                              break
                          }
                        }
                      }
                    }
                  }
                  if (key.k === 'limits') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          return value?.spaces
                        }
                      }
                    }
                  }
                  if (key.k === 'type') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if (props.types) {
                            const thisType = props.types.find(t =>
                              value.includes(t._id)
                            )
                            return thisType && thisType.name
                          }
                          return ''
                        }
                      }
                    }
                  }
                  return {
                    name: key.k,
                    label: key.l,
                    selector: key.k,
                    options: {
                      filter: true,
                      sort: true,
                      display:
                        key.k !== '_id' &&
                        key.k !== '__v' &&
                        key.k !== 'files' &&
                        key.k !== 'image'
                    }
                  }
                })}
                options={options}
              />
            </div>
          </div>
        )}
        {openModal && (
          <Edit
            data={openModal}
            form={collumns}
            set={set}
            types={types}
            setOpenModal={setOpenModal}
          />
        )}
      </Box>
      <Box w="30%" className={'map'}>
        <Map
          center={resources.map(resource => {
            const thisType = types.find(
              type => resource.type && resource.type.includes(type._id)
            )
            return {
              center: resource.location,
              name: resource.name,
              description: resource.description,
              color: thisType ? thisType.color : undefined
            }
          })}
        ></Map>
      </Box>
    </div>
  )
}
