import React, { useState } from 'react'
import { Container } from 'react-smooth-dnd'
import { Button, Input, Select } from '@chakra-ui/core'
import { useGlobalState } from '../../store'
import { DebounceInput } from 'react-debounce-input'

import Resource from './Resource'
import Person from './Person'

import {
  ResourcePicker,
  ResourceTab,
  ResourcePickerSearch,
  ResourcePickerContainer
} from './styled'

export default function(props) {
  const [pickerSource, setPickerSource] = useGlobalState('pickerSource')
  const [search, setSearch] = useState(null)
  const [searchType, setSearchType] = useState(null)
  const filterCards = () => {
    return props[pickerSource].filter(card => {
      let isAllocated = false
      //if we are in day view
      if (props.dayResources && props.dayResources.length > 0) {
        isAllocated = props.dayResources.find(t => {
          return card._id === t._id
        })
      }

      //first we find out the types
      let type = ''
      if (card.role) {
        type = props.userTypes.find(t => card.role === t._id)
      } else {
        type = props.resourceTypes.find(t => card.type === t._id)
      }

      if (
        searchType &&
        (card.type !== searchType && card.role !== searchType) &&
        !isAllocated
      )
        return null

      //then we filter
      if (search) {
        var re = new RegExp(search.toLowerCase().replace(' ', '(.*?)'), 'g')
        return (
          (type && type.name && type.name.toLowerCase().match(re)) ||
          (card.name && card.name.toLowerCase().match(re)) ||
          (card.firstName &&
            card.lastName &&
            `${card.firstName} ${card.lastName}`.toLowerCase().match(re)) ||
          (card.firstName &&
            card.firstName.toLowerCase().match(search.toLowerCase())) ||
          (card.lastName &&
            card.lastName.toLowerCase().match(search.toLowerCase())) ||
          (card.phone &&
            card.phone.toLowerCase().match(search.toLowerCase())) ||
          (card.code &&
            card.code.toLowerCase().match(search.toLowerCase()) &&
            !isAllocated)
        )
      }

      return !isAllocated
    })
  }
  let types =
    pickerSource === 'resources' ? props.resourceTypes : props.userTypes

  return (
    <ResourcePicker>
      <ResourcePickerSearch>
        {pickerSource && (
          <>
            {/* <Input
              id="standard-search"
              label="Search field"
              type="search"
              placeholder={'pesquisa...'}
              onChange={e => setSearch(e.target.value)}
              width={'150px'}
              // className={classes.textField}
            /> */}
            <DebounceInput
              style={{
                padding: '.5em',
                border: '1px solid #E2E8F0',
                borderRadius: 5,
                width: '150px'
              }}
              placeholder={'pesquisa...'}
              type={'text'}
              value={search || ''}
              minLength={1}
              debounceTimeout={500}
              onChange={e => setSearch(e.target.value)}
            />

            <Select
              onChange={event => setSearchType(event.target.value)}
              placeholder="Todos"
              color={'#ccc'}
              width={'10%'}
              value={searchType}
            >
              {types.map(
                t => t.status === 1 && <option value={t._id}> {t.name}</option>
              )}
            </Select>
          </>
        )}
        <ResourceTab>
          <Button
            // active={this.state.view === 'teams'}
            fontSize={'.8em'}
            width={'50%'}
            position={'relative'}
            className={pickerSource === 'resources' ? 'active' : ''}
            onClick={() => {
              if (pickerSource !== 'resources') {
                setPickerSource('resources')
                setSearchType(null)
              } else {
                setPickerSource(null)
              }
            }}
          >
            <span className={`icon-wrench icons`} />
            {'  '}Material
          </Button>
          <Button
            //active={this.state.view === 'users'}
            width={'50%'}
            fontSize={'.8em'}
            position={'relative'}
            className={pickerSource === 'users' ? 'active' : ''}
            onClick={() => {
              if (pickerSource !== 'users') {
                setPickerSource('users')
                setSearchType(null)
              } else {
                setPickerSource(null)
              }
            }}
          >
            <span className={`icon-people icons`} />
            {'  '}Pessoal
          </Button>

          {/*  buttons */}
        </ResourceTab>
      </ResourcePickerSearch>
      {pickerSource && (
        <ResourcePickerContainer>
          <Container
            orientation="horizontal"
            // groupName={pickerSource === 'resources' ? 'user' : 'project'}
            groupName={'userProject'}
            onDrop={e => props.dropResourceCard(e)}
            getChildPayload={index => {
              const availCards = filterCards()
              return (
                props[pickerSource] && {
                  ...availCards[index],
                  cardType: pickerSource,
                  source: 'picker'
                }
              )
            }}
            removeOnDropOut={true}
            dragClass="card-add-ghost"
            dropClass="card-add-ghost-drop"
            dropPlaceholder={{
              animationDuration: 150,
              showOnTop: true,
              className: 'drop-preview'
            }}
            dropPlaceholderAnimationDuration={200}
          >
            {props[pickerSource] &&
              filterCards().map((resource, i) => {
                if (i > 200) return null
                if (pickerSource === 'resources') {
                  return (
                    <Resource
                      key={i}
                      data={resource}
                      types={props.resourceTypes}
                      onClick={props.onClick}
                      simplified
                    />
                  )
                }

                return (
                  <Person
                    key={i}
                    data={resource}
                    types={props.userTypes}
                    onClick={props.onClick}
                    simplified
                  />
                )
              })}
          </Container>
        </ResourcePickerContainer>
      )}
    </ResourcePicker>
  )
}
