import { theme } from '@chakra-ui/core'

// 2. declare your configuration, these are the defaults
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

// example theme.js
export default {
  ...theme,
  breakpoints: ['360px', '768px', '1024px', '1440px'],
  config,
  fonts: {
    heading: '"Avenir Next", sans-serif',
    body: 'system-ui, sans-serif',
    mono: 'Menlo, monospace'
  },
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac'
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem'
  }
}
