import React from 'react'
import { AproveController } from './styled'
import { IconButton } from '@chakra-ui/core'
import { setRemoteCall } from '../../remote'

export default props => {
  const approveRelation = e => {
    setRemoteCall(
      'aproveRelation',
      {
        _id: props.relationId
      },
      null
    )
  }

  const cancelRelation = e => {
    setRemoteCall(
      'cancelRelation',
      {
        _id: props.relationId
      },
      null
    )
  }
  return (
    <AproveController>
      <IconButton
        height={'1rem'}
        width={'1rem'}
        fontSize="8px"
        aria-label="reject"
        icon="close"
        size="sm"
        variant="outline"
        onClick={() => cancelRelation()}
      />
      <IconButton
        aria-label="approve"
        icon="check"
        variant="outline"
        size="sm"
        height={'1rem'}
        width={'1rem'}
        fontSize="8px"
        onClick={() => approveRelation()}
      />
    </AproveController>
  )
}
