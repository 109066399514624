import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  Button,
  Select,
  InputLeftAddon,
  InputGroup,
  Input,
  Textarea,
  Box,
  Text,
  Flex
} from '@chakra-ui/core'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/core'
import { getGlobalState, useGlobalState } from '../store'
import Upload from '../components/Upload'

import Map from '../components/Map/Map'
import Timeline from '../components/Timeline/Timeline'
import Availability from '../components/Form/Availability'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
export default function Edit(props) {
  const [details, setDetails] = useState(props.data || {})
  const [location, setLocation] = useState(
    props.data ? props.data.location : {}
  )
  const [view, setView] = useState('info')
  const projects = getGlobalState('projects')
  const relations = getGlobalState('relations')

  let thisProject = {}

  const thisRel = relations.find(rel => {
    return rel.accountId && rel.accountId.includes(details._id)
  })
  if (thisRel)
    thisProject = projects.find(pr => thisRel.projectId.includes(pr._id))

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }
  const handleFormAttrFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, attr: { ...details.attr, [key]: value } })
  }
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Edit {details ? details.firstName : ''} details{' '}
          <Button
            size={'sm'}
            mr={3}
            variant={view === 'info' ? 'solid' : 'outline'}
            onClick={() => setView('info')}
          >
            Info
          </Button>
          <Button
            size={'sm'}
            variant={view === 'availability' ? 'solid' : 'outline'}
            mr={3}
            onClick={() => {
              setView('availability')
            }}
          >
            Ausências
          </Button>
          <Button
            size={'sm'}
            variant={view === 'history' ? 'solid' : 'outline'}
            mr={3}
            onClick={() => {
              setView('history')
            }}
          >
            Histórico
          </Button>
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {props.form &&
            view === 'info' &&
            props.form.map((k, i) => {
              if (k.k === '_id') return null

              if (k.t === 'upload') {
                return (
                  <Box key={i} margin={'2em 0'}>
                    {details[k.k] && (
                      <img
                        style={{
                          width: '100%',
                          height: '20vh',
                          objectFit: 'contain'
                        }}
                        src={details[k.k]}
                      />
                    )}
                    <Upload
                      key={i}
                      callback={files =>
                        handleFormFieldChange(k.k, { target: { value: files } })
                      }
                    />
                  </Box>
                )
              }
              if (k.t === 'files') {
                return (
                  <Box key={i} margin={'2em 0'}>
                    <Flex margin={'2em 0'} flexWrap={'wrap'}>
                      {details[k.k] &&
                        details[k.k].map((file, i) => {
                          let fileName = file.src.split(/\./)
                          return (
                            <div
                              key={i}
                              style={{
                                boxShadow: '0px 4px 8px -4px rgb(0 0 0 / 15%)',
                                padding: '1em',
                                borderRadius: '3px',
                                background: '#fafafa',
                                fontSize: '.9em',
                                position: 'relative',
                                margin: '0 1em 1em 0'
                              }}
                            >
                              <div
                                className="icon-minus"
                                onClick={() => {
                                  handleFormFieldChange(k.k, {
                                    target: {
                                      value: details[k.k].filter(
                                        (f, a) => a !== i
                                      )
                                    }
                                  })
                                }}
                                style={{
                                  position: 'absolute',
                                  top: '-.3em',
                                  right: '-.3em',
                                  width: '20px',
                                  height: '20px',
                                  cursor: 'pointer'
                                }}
                              />
                              <a href={file.src} target={'_blank'}>
                                {file.name || <strong>{fileName[1]}</strong>}
                                <br />
                                <span style={{ fontSize: '.7em' }}>
                                  {dayjs(file.date).format('DD-MM-YYYY')}
                                </span>
                              </a>
                            </div>
                          )
                        })}
                    </Flex>
                    <Box margin={'2em 0'}>
                      <Upload
                        key={i}
                        fullCallback={files => {
                          handleFormFieldChange(k.k, {
                            target: {
                              value: [
                                ...(details[k.k] || []),
                                {
                                  date: new Date(),
                                  src: files.link,
                                  name: files.name
                                }
                              ]
                            }
                          })
                        }}
                      />
                    </Box>
                  </Box>
                )
              }
              if (k.k === 'role' && props.types)
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        t =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'transport')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event =>
                        handleFormAttrFieldChange('transport', event)
                      }
                      placeholder="Select option"
                      value={details.attr ? details.attr.transport : 0}
                    >
                      <option value={0}> Precisa de Transporte </option>
                      <option value={1}> Tem Transporte </option>
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'admin')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      <option value={1}> Ativo </option>
                      <option value={0}> Inativo </option>
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'status')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      <option value={0}> Inativo </option>
                      <option value={1}> Activo </option>
                      <option value={2}> Indisponível </option>
                    </Select>
                  </InputGroup>
                )
              if (k.t === 'textarea') {
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <Textarea
                      padding={'1.5em .5em'}
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder={k.l}
                      type={k.t}
                      value={details[k.k]}
                    />
                  </InputGroup>
                )
              }

              if (k.t === 'map') {
                return (
                  <div style={{ height: '300px' }}>
                    <Map
                      location={location}
                      updateLatLon={(lat, lng) => setLocation({ lat, lng })}
                    />
                  </div>
                )
              }
              return (
                <InputGroup key={i} margin={'1em 0'} size="sm">
                  <InputLeftAddon
                    minWidth={'120px'}
                    textAlign={'center'}
                    padding={'1.45em .5em'}
                    children={k.l}
                    fontWeight={'bold'}
                  />
                  <Input
                    padding={'.5em .5em'}
                    height={'3em'}
                    onChange={event => handleFormFieldChange(k.k, event)}
                    type={k.t || 'text'}
                    placeholder={k.k}
                    value={details[k.k]}
                  />
                </InputGroup>
              )
            })}
          {view === 'availability' && (
            <>
              <Text fontSize={'.8em'} fontWeight={'bold'}>
                Indisponibilidade
              </Text>
              <Availability
                data={details.availability}
                setData={data =>
                  handleFormFieldChange('availability', {
                    target: { value: data }
                  })
                }
              />
            </>
          )}
          {view === 'history' && <Timeline type={'user'} id={details._id} />}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={() => props.setOpenModal()}>
            Cancel
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              props.set(
                { ...details, location: location },
                ` updating user`,
                'users'
              )
              props.setOpenModal()
            }}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
