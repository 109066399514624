import React from 'react'
import {
  PersonCard,
  PersonCardEmpty,
  ResourceInContent,
  ResourcesTitle,
  ResourcesDetail,
  AproveController
} from './styled'
import { getGlobalState } from '../../store'
import Approve from './Approve'
import { Flex } from '@chakra-ui/core'
import AirportShuttleRoundedIcon from '@material-ui/icons/AirportShuttleRounded'
export default props => {
  const authors = getGlobalState('users')
  const person = props.data
  const type = props.types ? props.types.find(t => t._id === person.role) : {}
  const author = authors ? authors.find(t => t._id === person.author) : {}
  if (props.viewType && props.viewType === 'resources') {
    return <PersonCardEmpty>{props.children}</PersonCardEmpty>
  }

  let needsTransportation = true

  if (
    props.data &&
    props.data.attr &&
    props.data.attr.transport &&
    props.data.attr.transport === '1'
  ) {
    needsTransportation = false
  }

  return (
    <PersonCard
      disabledrag={props.disabledrag ? 'true' : 'false'}
      willChange={props.willChange ? 'true' : 'false'}
      hasChanged={props.hasChanged ? 'true' : 'false'}
      approved={props.approved}
    >
      <ResourceInContent>
        <span
          style={{ background: type ? type.color : '' }}
          onClick={() =>
            props.onClick && props.onClick(props.data._id, 'people')
          }
        >
          {person.code}

          {needsTransportation && (
            <AirportShuttleRoundedIcon
              style={{ width: '13px', height: '13px', marginLeft: '.2em' }}
            />
          )}
        </span>
        <div>
          <ResourcesTitle>
            <Flex style={{ justifyContent: 'space-between' }}>
              <div
                className="name"
                onClick={() =>
                  props.onClick && props.onClick(props.data._id, 'people')
                }
              >
                {person.firstName} {person.lastName}{' '}
              </div>
              {!props.simplified && author && (
                <div className="author" style={{ textAlign: 'right' }}>
                  {author.firstName}
                  {/* <i className={`icon-people icons`} /> */}
                </div>
              )}
            </Flex>
          </ResourcesTitle>
          {props.approved === 'false' && (
            <Approve relationId={props.data.relationId} />
          )}

          {type && (
            <ResourcesDetail className={'pickerOnly'}>
              {type.name}
            </ResourcesDetail>
          )}
        </div>
      </ResourceInContent>
      <div>{props.children}</div>
    </PersonCard>
  )
}
