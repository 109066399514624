import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useGlobalState } from '../store'
import {
  Button,
  Select,
  InputLeftAddon,
  InputGroup,
  Input,
  Textarea
} from '@chakra-ui/core'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/core'

import Map from '../components/Map/Map'
import Timeline from '../components/Timeline/Timeline'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
export default function Edit(props) {
  const [details, setDetails] = useState(props.data || {})
  const [view, setView] = useState('info')
  const [location, setLocation] = useState(
    props.data ? props.data.location : {}
  )
  const [types, setProjectTypes] = useGlobalState('projectTypes')

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }

  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Edit {details ? details.name : ''} details{' '}
          <Button
            size={'sm'}
            mr={3}
            variant={view === 'info' ? 'solid' : 'outline'}
            onClick={() => setView('info')}
          >
            Info
          </Button>
          <Button
            size={'sm'}
            variant={view === 'history' ? 'solid' : 'outline'}
            mr={3}
            onClick={() => {
              setView('history')
            }}
          >
            Histórico
          </Button>
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {props.form &&
            view === 'info' &&
            props.form.map(k => {
              if (k.k === '_id') return null
              if (k.k === 'type' && types) {
                return (
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      {types.map(t => (
                        <option value={t._id}> {t.name}</option>
                      ))}
                    </Select>
                  </InputGroup>
                )
              }
              if (k.t === 'map') {
                return (
                  <div style={{ height: '300px' }}>
                    <Map
                      location={location}
                      updateLatLon={(lat, lng) => setLocation({ lat, lng })}
                    />
                  </div>
                )
              }
              if (k.t === 'datetime') {
                return (
                  <>
                    <InputGroup margin={'1em 0'} size="sm">
                      <InputLeftAddon
                        minWidth={'120px'}
                        textAlign={'center'}
                        padding={'1.45em .5em'}
                        children={k.l}
                        fontWeight={'bold'}
                      />
                      <Input
                        padding={'.5em .5em'}
                        height={'3em'}
                        onChange={event => handleFormFieldChange(k.k, event)}
                        type={'date'}
                        placeholder={k.l}
                        value={dayjs(details[k.k]).format('YYYY-MM-DD')}
                      />
                    </InputGroup>
                  </>
                )
              }
              if (k.k === 'status')
                return (
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      <option value={0}> Desativo </option>
                      <option value={1}> Activo </option>
                    </Select>
                  </InputGroup>
                )

              if (k.t === 'textarea') {
                return (
                  <>
                    <InputGroup margin={'1em 0'} size="sm">
                      <Textarea
                        padding={'1.5em .5em'}
                        onChange={event => handleFormFieldChange(k.k, event)}
                        placeholder={k.l}
                        type={k.t || 'text'}
                        value={details[k.k]}
                      />
                    </InputGroup>
                  </>
                )
              }
              return (
                <>
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.45em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Input
                      padding={'.5em .5em'}
                      height={'3em'}
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder={k.l}
                      type={k.t || 'text'}
                      value={details[k.k]}
                    />
                  </InputGroup>
                </>
              )
            })}
          {view === 'history' && <Timeline type={'project'} id={details._id} />}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={() => props.setOpenModal()}>
            Cancel
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              props.set(
                { ...details, location: location },
                ` updating projects`,
                'projects'
              )
              props.setOpenModal()
            }}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
