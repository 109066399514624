import React, { useState } from 'react'
import './Login.css'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Button, Flex, Input } from '@chakra-ui/core'
import { setGlobalState, setStorageState } from '../store'

function Login(props) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  setGlobalState('pageClass', 'users')

  const resetPassword = async e => {
    if (!username || username === '') {
      toast('Por favor introduza o email')
    }

    await axios.post(`/auth/forgot`, {
      email: username
    })
    toast('Verifique a sua caixa de correio')
  }

  const loginUser = async e => {
    e.preventDefault()

    if (username === '' || password === '') {
      return toast('Por favor introduza o email e password')
    }

    try {
      const response = await axios.post(`/auth`, {
        email: username,
        password
      })
      if (response.data && response.data.token) {
        await setStorageState('loggedIn', response.data)
        props.history.push('/dashboard')
      }
    } catch (e) {
      console.log(e)
      setStorageState('loggedIn', null)
      toast('Verifique os seus dados')
    }
  }

  return (
    <Flex
      align="center"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100vh"
      backgroundImage={'url(/images/home.jpg)'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
    >
      <Box
        className={'loginwrap'}
        maxW="md"
        bg="white"
        width={'40%'}
        height={'100vh'}
        padding={'2em'}
        shadow="md"
        borderWidth="0"
        rounded="lg"
        position={'absolute'}
        left="0"
      >
        <div className={'login-container'}>
          <div>
            <h1>Tecnorem </h1>
            <form className="login-form" onSubmit={loginUser} data-login-form>
              <Input
                data-login-email
                onChange={e => setUsername(e.target.value)}
                placeholder="Email"
              />
              <Input
                type="password"
                data-login-password
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
              />

              <Button
                onClick={e => loginUser(e)}
                variantColor="teal"
                value="Submit!"
                width={'100%'}
              >
                Submit!
              </Button>
            </form>
            <a href={'#'} onClick={() => resetPassword()}>
              Reset Password
            </a>
          </div>
        </div>
      </Box>
    </Flex>
  )
}

export default withRouter(Login)
