import React, { useState } from 'react'
import Header from '../components/header'
import { exportComponentAsJPEG } from 'react-component-export-image'

import {
  Grid,
  Flex,
  Box,
  Button,
  InputGroup,
  InputLeftAddon,
  Input,
  Select
} from '@chakra-ui/core'
import Boards from '../components/Boards'
import { BoardWrapper } from './styled.js'
import dayjs from 'dayjs'
import { setGlobalState, useGlobalState, setStorageState } from '../store'
import { DebounceInput } from 'react-debounce-input'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
export default function Dashboard(props) {
  const [userTypes, setUserTypes] = useGlobalState('userTypes')
  const [search, setSearch] = useState('')
  setGlobalState('pageClass', 'users')
  const [viewOptions, setviewOptions] = useGlobalState('viewOptions')
  const [printing, setPrinting] = useState(false)
  const ref = React.useRef(null)
  const [documentSize, setDocumentSize] = useState({
    height: 595.28,
    width: 841.89
  })

  const options = {
    filterType: 'dropdown'
  }
  return (
    <div className={'wrapper'}>
      <Header>
        {/* <button onClick={() => props.socket.emit('page', 'planning')}>
          Isto é o que está a acontecer hoje
        </button> */}
        <Flex
          alignItems={['center']}
          display={{ base: 'block', md: 'flex' }}
          justifyItems={[
            'flex-start',
            'flex-start',
            'flex-start',
            'space-between'
          ]}
          justifyContent={['space-between']}
          flexDirection={['column', 'column', 'row', 'row']}
          fontSize={{ base: '.8em', md: '1em' }}
        >
          <Box>
            <h1>
              Planeamento{' '}
              <button
                onClick={() => {
                  exportComponentAsJPEG(ref)
                }}
              >
                <span
                  style={{ fontSize: '.6em' }}
                  className={`icon-printer icons`}
                />
              </button>
            </h1>
            <Box margin={'0 0 0 0 '}>
              <InputGroup margin={'0 0'} size="sm">
                <InputLeftAddon
                  textAlign={'center'}
                  cursor={'pointer'}
                  padding={'1.3em .5em'}
                  children={'‹'}
                  onClick={event =>
                    setviewOptions({
                      ...viewOptions,
                      date: dayjs(viewOptions.date)
                        .add(-1, viewOptions.displayType || 'week')
                        .format('YYYY-MM-DD')
                    })
                  }
                  fontWeight={'bold'}
                  // _hover={{ color: 'red' }}
                />
                <Input
                  padding={'.5em .5em'}
                  height={'2.8em'}
                  onChange={event =>
                    setviewOptions({
                      ...viewOptions,
                      date: event.target.value
                    })
                  }
                  type={'date'}
                  value={dayjs(viewOptions.date).format('YYYY-MM-DD')}
                />
                <InputLeftAddon
                  textAlign={'center'}
                  cursor={'pointer'}
                  padding={'1.3em .5em'}
                  children={'›'}
                  onClick={event =>
                    setviewOptions({
                      ...viewOptions,
                      date: dayjs(viewOptions.date)
                        .add(1, viewOptions.displayType || 'week')
                        .format('YYYY-MM-DD')
                    })
                  }
                  fontWeight={'bold'}
                  // _hover={{ color: 'red' }}
                />
              </InputGroup>
            </Box>
          </Box>
          <Flex
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'flex-end!important'}
            justifyContent={'flex-end'}
          >
            <Flex>
              <Box>
                <DebounceInput
                  style={{
                    padding: '.5em',
                    border: '1px solid #E2E8F0',
                    borderRadius: 5
                  }}
                  placeholder={'pesquisar projetos'}
                  type={'text'}
                  value={viewOptions.search || ''}
                  minLength={1}
                  debounceTimeout={500}
                  onChange={event =>
                    setStorageState('viewOptions', {
                      ...viewOptions,
                      search: event.target.value
                    })
                  }
                />

                {/* <Input
                padding={'.5em .5em'}
                placeholder={'pesquisar projetos'}
                onChange={event =>
                  setStorageState('viewOptions', {
                    ...viewOptions,
                    search: event.target.value
                  })
                }
                type={'text'}
                value={viewOptions.search || ''}
              /> */}
              </Box>
              <Box>
                {userTypes && (
                  <Select
                    onChange={event => {
                      return setStorageState('viewOptions', {
                        ...viewOptions,
                        type: event.target.value
                      })
                    }}
                    placeholder="Todos"
                    color={'#ccc'}
                    value={viewOptions.type}
                  >
                    {userTypes
                      .sort((a, b) => (b.name < a.name ? 1 : -1))
                      .map(
                        t =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                  </Select>
                )}
              </Box>
            </Flex>
            <Box margin={'0'} fontSize={'.8em'}>
              <Button
                variantColor={'gray'}
                variant={
                  viewOptions.displayType === 'day' ? 'solid' : 'outline'
                }
                width={'20%'}
                onClick={() =>
                  setStorageState('viewOptions', {
                    ...viewOptions,
                    displayType: 'day'
                  })
                }
              >
                <span className={`icon-book-open icons`} />
              </Button>
              <Button
                variantColor={'gray'}
                variant={
                  viewOptions.displayType === 'week' ? 'solid' : 'outline'
                }
                width={'20%'}
                onClick={() =>
                  setStorageState('viewOptions', {
                    ...viewOptions,
                    displayType: 'week'
                  })
                }
              >
                <span className={`icon-list icons`} />
              </Button>
              <Button
                variantColor={'gray'}
                variant={viewOptions.showOnlyTransfers ? 'solid' : 'outline'}
                width={'20%'}
                onClick={() =>
                  setStorageState('viewOptions', {
                    ...viewOptions,
                    showOnlyTransfers: !viewOptions.showOnlyTransfers
                  })
                }
              >
                <span className={`icon-organization icons`} />
              </Button>
              <Button
                variantColor={'gray'}
                variant={
                  viewOptions.viewType === 'users' ||
                  viewOptions.viewType === 'all'
                    ? 'solid'
                    : 'outline'
                }
                width={'20%'}
                onClick={() => {
                  let newView = 'users'
                  if (viewOptions.viewType === 'users') {
                    newView = 'global'
                  } else if (viewOptions.viewType === 'resources') {
                    newView = 'all'
                  }
                  setStorageState('viewOptions', {
                    ...viewOptions,
                    viewType: newView
                  })
                }}
              >
                <span className={`icon-people icons`} />
              </Button>
              <Button
                variantColor={'gray'}
                variant={
                  viewOptions.viewType === 'resources' ||
                  viewOptions.viewType === 'all'
                    ? 'solid'
                    : 'outline'
                }
                width={'20%'}
                onClick={() => {
                  let newView = 'resources'
                  if (viewOptions.viewType === 'resources') {
                    newView = 'global'
                  } else if (viewOptions.viewType === 'users') {
                    newView = 'all'
                  }
                  setStorageState('viewOptions', {
                    ...viewOptions,
                    viewType: newView
                  })
                }}
              >
                <span className={`icon-wrench icons`} />
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Header>

      <BoardWrapper ref={ref}>
        <Boards />
      </BoardWrapper>
    </div>
  )
}
