import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGlobalState, setStorageState, getGlobalState } from '../../store'
import dayjs from 'dayjs'

import { showNotification } from '../../notifications'
import {
  ListContainer,
  StyledDrawer,
  StyledListItem,
  StyledNavigationPanel,
  MenuIcon,
  StyledLogo,
  NumberToApprove,
  StyledListWrap,
  StyledList
} from './styled'

import { Box } from '@chakra-ui/core'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
const Navigation = props => {
  const [show, setShow] = useState(false)
  // const [showApproval, setShowApproval] = useState(false)
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn')
  const handleToggle = () => setShow(!show)
  const [relations, setRelations] = useGlobalState('relations')
  const socket = getGlobalState('socket')
  const [viewOptions, setviewOptions] = useGlobalState('viewOptions')

  const logout = async () => {
    await socket.close()
    setStorageState('loggedIn', null)
    setLoggedIn(null)
  }

  const toApprove = relations.filter(rel => rel.status === 1) || []
  if (toApprove.length > 0) {
    showNotification(
      'Aprovações',
      `Existem ${toApprove.length} alterações por rever`
    )
  } else if (toApprove.length < 1 && viewOptions.showApproval) {
    setStorageState('viewOptions', {
      ...viewOptions,
      showApproval: false,
      viewType: 'global'
    })
  }

  return (
    <StyledNavigationPanel>
      <StyledDrawer variant="permanent" anchor="left">
        <ListContainer>
          <div>
            <StyledLogo onClick={() => handleToggle()} />
            {toApprove.length > 0 && (
              <NumberToApprove
                onClick={() =>
                  setStorageState('viewOptions', {
                    ...viewOptions,
                    showApproval: !viewOptions.showApproval,
                    viewType: !viewOptions.showApproval ? 'all' : 'global'
                  })
                }
                style={
                  toApprove.length > 9
                    ? { cursor: 'pointer' }
                    : { padding: '.3em 0.7em', cursor: 'pointer' }
                }
              >
                {toApprove.length}
              </NumberToApprove>
            )}
          </div>
          {!show && viewOptions.showApproval && (
            <StyledListWrap>
              {toApprove.map((a, i) => (
                <Box
                  key={i}
                  color={'#fff'}
                  cursor={'pointer'}
                  fontSize={'.7em'}
                  borderBottom={'1px solid rgba(255,255,255,.5)'}
                  paddingBottom={'.5em'}
                  marginBottom={'.5em'}
                  textAlign={'center'}
                  // _hover={{ background: '#ffffff' }}
                  onClick={() =>
                    setStorageState('viewOptions', {
                      ...viewOptions,
                      date: dayjs(a.startDate).format('YYYY-MM-DD')
                    })
                  }
                >
                  {dayjs(a.startDate).format('DD/MM/YYYY')}
                </Box>
              ))}
            </StyledListWrap>
          )}
          {show && (
            <StyledListWrap>
              <StyledList>
                <StyledListItem>
                  <Link to={'/dashboard'} onClick={() => handleToggle()}>
                    <MenuIcon className={`icon-layers icons`} />
                    <span>Geral</span>
                  </Link>
                </StyledListItem>
                <StyledListItem>
                  <Link to={'/resources'} onClick={() => handleToggle()}>
                    <MenuIcon className={`icon-wrench icons`} />
                    <span>Recursos</span>
                  </Link>
                </StyledListItem>

                <StyledListItem>
                  <Link to={'/users'} onClick={() => handleToggle()}>
                    <MenuIcon className={`icon-people icons`} />
                    <span>Pessoal</span>
                  </Link>
                </StyledListItem>

                <StyledListItem>
                  <Link to={'/projects'} onClick={() => handleToggle()}>
                    <MenuIcon className={`icon-location-pin icons`} />
                    <span>Projetos</span>
                  </Link>
                </StyledListItem>
                <StyledListItem>
                  <Link to={''} onClick={() => logout()} border="1px">
                    <MenuIcon className={`icon-key icons`} />
                    <span>logout</span>
                  </Link>
                </StyledListItem>
              </StyledList>
            </StyledListWrap>
          )}
        </ListContainer>
      </StyledDrawer>
    </StyledNavigationPanel>
  )
}

export default Navigation
