import React, { useState, useEffect, useMemo } from 'react'
import { useGlobalState } from '../../store'
import ResourceGroupCard from './ResourceGroupCard'
import { useRef } from 'react'
import AirportShuttleRoundedIcon from '@material-ui/icons/AirportShuttleRounded'

import {
  ProjectTitle,
  ProjectColor,
  Resources,
  ResourceList,
  RelativeWrapper,
  StyledContainer,
  Project
} from './styled'

const ProjectComponent = ({
  column,
  pickerSource,
  viewOptions,
  onClick,
  onResourceCardDrop,
  type,
  project,
  sortedProjectResources,
  sortedGroups,
  projectResources,
  usedProjectGroups,
  projectResourcesGroup,
  resourceTypes
}) => {
  let ref = useRef(null)

  const [toggleCollapse, setToggleCollapse] = useState(false)
  const [visible, setVisibility] = useState(false)
  let projectResourceVisi = []
  let availableRides = 0
  let amountOfPeople = 0
  let vehiclesIds = []
  // get vehiclesIDs
  if (resourceTypes) {
    vehiclesIds = resourceTypes
      .filter(type => type.name === 'Ligeiro' || type.name === 'Pesado')
      .map(s => s._id)
  }

  projectResourceVisi = useMemo(
    () =>
      (projectResources || []).filter(r => {
        if (viewOptions.type) {
          return r.type == viewOptions.type || r.role == viewOptions.type
        } else {
          return r
        }
      }),
    [projectResources]
  )
  sortedProjectResources.map((resource, a) => {
    if (typeof resource === 'object') {
      // console.log(resource)
      if (resource.cardType === 'users') {
        if (resource?.attr?.transport === '1') {
        } else {
          amountOfPeople += 1
        }
        //check if it needs a ride
      } else if (vehiclesIds.includes(resource.type)) {
        if (resource.limits && resource.limits.spaces) {
          availableRides += Number(resource.limits.spaces)
        }
      }
      // if(resource.)
    }
  })

  // onScreen = useOnScreen(ref, '0px')
  return (
    (projectResourceVisi.length >= 1 || !viewOptions.type) && (
      <ProjectColor
        className={`animated fadeIn ${project.id}`}
        color={type && type.color}
      >
        <ProjectTitle
          cursor={'pointer'}
          color={type && type.color}
          style={{
            color: type ? type.color : '#676a7a'
          }}
        >
          <span
            style={{
              background: type ? type.color : ''
            }}
            onClick={() => onClick(project.id, 'project')}
          >
            {project.code}
          </span>{' '}
          {project.name}{' '}
        </ProjectTitle>

        <Project key={project.id}>
          <div {...project.props} style={{ position: 'relative' }}>
            <Resources>
              <ResourceList>
                {!pickerSource &&
                  sortedGroups.map((group, i) => {
                    return (
                      <ResourceGroupCard
                        group={group}
                        setVisibility={setVisibility}
                        viewOptions={viewOptions}
                        onClick={onClick}
                        usedProjectGroups={usedProjectGroups}
                        onResourceCardDrop={onResourceCardDrop}
                        projectResourcesGroup={projectResourcesGroup}
                        pickerSource={pickerSource}
                        column={column}
                        project={project}
                        projectResources={projectResources}
                        sortedProjectResources={sortedProjectResources}
                      />
                    )
                  })}
                {pickerSource && (
                  <StyledContainer
                    groupName="userProject"
                    onDrop={e => {
                      onResourceCardDrop(column.id, project.id, null, e)
                    }}
                    getChildPayload={index => {
                      return {
                        ...sortedProjectResources.filter(r => !r.accountId)[
                          index
                        ],
                        projectId: project.id,
                        columnId: column.id,
                        source: 'calendar'
                      }
                    }}
                    dragClass="project-ghost"
                    dropClass="project-ghost-drop"
                    dropPlaceholder={{
                      animationDuration: 150,
                      showOnTop: true,
                      className: 'drop-preview'
                    }}
                    dropPlaceholderAnimationDuration={200}
                  >
                    {sortedGroups.map((group, i) => {
                      return (
                        <ResourceGroupCard
                          group={group}
                          setVisibility={setVisibility}
                          pickerSource={pickerSource}
                          viewOptions={viewOptions}
                          column={column}
                          project={project}
                          onClick={onClick}
                          projectResources={projectResources}
                          usedProjectGroups={usedProjectGroups}
                          sortedProjectResources={sortedProjectResources}
                          onResourceCardDrop={onResourceCardDrop}
                          projectResourcesGroup={projectResourcesGroup}
                        />
                      )
                    })}
                  </StyledContainer>
                )}
              </ResourceList>
            </Resources>
            <span
              // onClick={() => {
              //   setToggleCollapse(
              //     toggleCollapse === project.id ? null : project.id
              //   )
              // }}
              style={{
                position: 'absolute',
                fontSize: '.65em',
                color: '#fff',
                borderRadius: '3px',
                padding: '0 .5em',
                right: 0,
                background:
                  type && amountOfPeople <= availableRides ? type.color : '#000'
              }}
            >
              <AirportShuttleRoundedIcon
                style={{ width: '13px', height: '13px', marginLeft: '.2em' }}
              />{' '}
              {amountOfPeople}/{availableRides}
            </span>
          </div>
        </Project>
      </ProjectColor>
    )
  )
}

export default ProjectComponent
