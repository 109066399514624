import styled, { css } from 'styled-components'
import Color from 'color'
import Cards from './Cards'
// import Card from '@material-ui/core/Card';
import { TextField, Select } from '@material-ui/core'
import { Draggable, Container } from 'react-smooth-dnd'

export const Week = styled.div`
  && {
    display: flex;
    margin-left: 0em;
    margin-bottom: 140px;
    /* font-size: 1em;
    font-style: bold; */
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
`

export const WeekdayWrap = styled.div`
  && {
    /* transform:translateZ(0.1px); */
    ${props =>
      props.size === 'day'
        ? css`
            column-count: 6;
            column-gap: 0;

            > div {
              display: inline-block;
              width: 100%;
            }

            @media (min-width: 3000px) {
              column-count: 9;
              column-gap: 0;
            }
          `
        : css`
            display: auto;
          `}

    @media (max-width: 1024px) {
      column-count: 3;
      column-gap: 0;

      > div {
        display: inline-block;
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      column-count: 2;
      column-gap: 0;

      > div {
        display: inline-block;
        width: 100%;
      }
    }
    @media (max-width: 400px) {
      column-count: 1;
      column-gap: 0;
    }
  }
`

export const Weekday = styled.div`
  && {
    width: ${props => (props.size === 'week' ? '25%' : '100%')};
    /* margin: 0em 0.1em 0em 0em; */
    .smooth-dnd-draggable-wrapper {
      /* background: #ffffff; */
      /* background: #fcf8f4; */

      /* background: red; */
      margin: 0 1em 1em;
      /* width: ${props => (props.size === 'week' ? 'auto' : '20%')}; */
      border-radius: 3px;
      /* box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15); */
      padding: .5em 0.2em;
      box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.15);
      .smooth-dnd-draggable-wrapper {
        width: auto !important;
        margin: 0 0 0.4em 0;
        padding: 0.4em 0.8em 0.4em 0.4em;
      }
      .pickerOnly {
        display: none;
      }
    }
    border-right:1px solid rgba(0,0,0,.05);
    &:nth-child(2n + 1) {
      /* background: #fafafa; */
      min-height: calc(100vh - 320px);
    }
    .smooth-dnd-container {
      min-height: 60px !important;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
   
  }
`

export const WeekdayTitle = styled.div`
  && {
    font-size: 1em;
    color: #ccd0d4;
    font-weight: bold;
    letter-spacing: 0.13px;
    margin: 0;
    padding: 1em 0 1em;
  }
`

export const TitleBar = styled.div`
  && {
    height: 3px;
    width: 60px;
    background: #2d2e3c;
    border-radius: 3px;
    margin: 0em 0em 1em 0em;
    padding: 0em 2em 0em 0em;
  }
`

export const ColummnTitle = styled.div`
  && {
    font-size: 1em;
    /* color: red; */
    color: #676a7a;
    font-weight: bold;
    letter-spacing: 0.13px;
    margin: 0 0 1em;
    padding: 0.8em 1em 0em 0.8em;
    div {
      width: 50%;
      margin: 0em 1em 0em0;
    }
    span {
      display: inline-block;
      font-size: 0.7em;
      opacity: 0.7;
    }
  }
`

export const ProjectColor = styled.div`
  && {
    max-width: 30vw;
    @media (max-width: 768px) {
      max-width: 47vw;
    }
    @media (max-width: 400px) {
      max-width: 90vw;
    }
    min-height: ${props => (props.height ? props.height : 'auto')};
    };
    .smooth-dnd-draggable-wrapper {
      background: ${props =>
        props.color ? `${Color(props.color).alpha(0.05)}` : '#fcf8f4'};
    }
    border-radius: 0 0 3px 3px;

    
  }
`

export const TotalResources = styled.div`
  && {
    display: inline;
    background: #ccc;
    color: #fff;
    border-radius: 3px;
    padding: 0.2em 0.5em;
    font-size: 0.9em;
    margin: 0 0.3em 0 0;
  }
`
export const RelativeWrapper = styled.div`
  && {
    position: relative;
  }
`
export const Project = styled(Cards)`
  && {
    /* background-color: red;
    position:relative;
    margin: 0 0.5em;
    border-radius: 3px;
    border: 2px solid #2d2e3b; */
    /* box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15); */
  }
`

export const ProjectTitle = styled.div`
  && {
    padding: 0.8em 0.5em;
    font-size: 0.8em;
    color: #676a7a;
    background: #fff;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    line-height: 1;
    margin: 0 1.2em;
    background: ${props =>
      props.color ? `${Color(props.color).alpha(0.3)}` : '#fff'};
    border-radius: 3px 3px 0 0;

    span {
      background: #ccc;
      color: #fff;
      border-radius: 3px;
      padding: 0.2em 0.5em;
      font-size: 0.9em;
      margin: 0 0.3em 0 0;
    }
    .open {
      opacity: 0.5;
      cursor: pointer;
      width: 0;
      height: 0;
      padding: 0;
      background: transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: ${props =>
        props.color
          ? `8px solid ${Color(props.color).alpha(0.6)}`
          : '8px solid #ccc'};
      margin-top: 0.3em;
    }
  }
`

export const ResourceTypeTitle = styled.div`
  && {
    font-weight: normal;
    font-size: 0.7em;
    padding: 1em 0 0 1em;
    /* padding-left: 0.7em;
    padding-bottom: 0.3em; */
    color: #676a7a;

    /* color: red; */
  }
`

export const ResourcesLabel = styled.div`
  && {
    font-size: 0.7em;
    color: #999;
    padding: 0.5em 1em;
  }
`
export const ResourceList = styled.div`
  && {
    margin: 0em;
    font-weight: bold;
    background: url('/images/cursor.svg') center 70% no-repeat transparent;
    background-size: 40px;

    /* background: red; */
    /* margin: 0.1em; */
    border-radius: 3px;
  }
`

export const ResourcesTitle = styled.div`
  && {
    font-weight: bold;
    font-size: 0.8em;
    padding-left: 0em;
    color: #474f58;
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
      align-items: flex-start;
    }
    i {
      opacity: 0.5;
      align-items: flex-end;
    }
  }
`

export const Resources = styled.div`
  && {
    margin: 0 0.5em;
    border: none;
    /* margin: 0.5em; */
    /* border-radius: 3px;
    border: 2px solid #2d2e3b; */
    /* padding: 0.5em; */

    font-weight: normal;
    .smooth-dnd-draggable-wrapper {
      /* background: #ffffff; */
      background: #ffffff;
      border: none;
    }
    .smooth-dnd-container {
      /* background: #e7e7e7;
      margin: 0.1em;
      border-radius: 3px;
      border: 1px solid #eeeeee;
      padding: 0.5em; */
    }
  }
`

export const ResourceInContent = styled.div`
  && {
    display: flex;
    align-items: center;
    position: relative;
    span {
      background: #ccc;
      color: #fff;
      border-radius: 3px;
      padding: 0em 0.3em;
    }
    div {
      flex: 1 auto;
      /* flex-direction: row; */
    }
  }
`

export const GroupCard = styled.div`
  && {
    cursor: pointer;
    width: auto !important;
    margin: 0 0 0.4em 0;
    padding: 0.4em 0.8em 0.4em 0.4em;
    border-radius: 3px;
    font-weight: bold;
    font-size: 0.7em;
    text-align: left;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.05);
    display: flex;
    justify-content: space-between;

    ${props =>
      props.color
        ? `
        background: ${Color(props.color).alpha(0.8)};
          `
        : 'background: #ffffff;  opacity: 0.3;  padding: 0.2em .5em;'}
  }
`
export const ResourceCard = styled(Draggable)`
  && {
    background: #ffffff;
    position: relative;
    cursor: grab;
    margin: 0.5em 0em, 0em, 0em;
    border-radius: 3px;
    font-weight: normal;
    font-size: 0.8em;
    text-align: left;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
    border: 1px solid #eeeeee;
    padding: 0 0 3em 0;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    ${props =>
      props.approved === 'false'
        ? `
            filter: grayscale(1);
          `
        : ''}

    span {
      display: flex;
      margin-right: 5px;
      align-items: center;
      min-width: 50px;
      text-align: center;
      font-size: 0.8em !important;
    }
    .author {
      font-size: 0.7em;
      opacity: 0.9;
      position: absolute;
      right: 0.5em;
      padding: 0 0.5em;
      background: white;
      display: inline-block;
    }
    min-width: 140px;
  }
`

export const ResourceHolder = styled(Container)`
  && {
    background: #ffffff;
    min-width: 140px;

    cursor: grab;
    margin: 0.5em 0em, 0em, 0em;
    border-radius: 3px;
    font-weight: normal;
    min-height: 40px !important;
    text-align: left;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
    border: 1px solid #eeeeee;
    padding: 0 0 3em 0;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    text-overflow: ellipsis;

    span {
      display: flex;
      margin-right: 5px;
      align-items: center;
    }
    .author {
      font-size: 0.7em;
      opacity: 0.9;
      position: absolute;
      right: 0.5em;
      padding: 0 0.5em;
      background: white;
      display: inline-block;
    }
  }
`

export const AproveController = styled.div`
  && {
  }
`

export const PersonCardEmpty = styled.div`
  && {
    .smooth-dnd-container {
      min-height: 0 !important;
    }
  }
`
export const PersonCard = styled(Draggable)`
  && {
    background: #ffffff;
    position: relative;
    min-width: 140px;
    cursor: grab;
    margin: 0.5em 0em, 0em, 0em;
    border-radius: 3px;
    font-weight: normal;
    font-size: 0.8em;
    text-align: left;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
    border: 1px solid #eeeeee;
    padding: 0 0 3em 0;
    ${props =>
      props.disabledrag === 'true'
        ? `
            cursor: pointer;
          `
        : ''}
    ${props =>
      props.approved === 'false'
        ? `
            filter: grayscale(1);
          `
        : ''}

    ${props =>
      props.willChange === 'true'
        ? `
            border: 2px solid #d9363673!important;
            background: #d9363624!important;
          `
        : ''}
    ${props =>
      props.hasChanged === 'true'
        ? `
            border: 2px dotted #1d981b69!important;
            background:#1d981b14!important;
          `
        : ''}

        
    span {
      align-items: center;
      display: flex;
      font-size: 0.8em !important;
      margin-right: 5px;
      text-align: center;
      padding: 0 0.5em;
    }
    .author {
      font-size: 0.7em;
      opacity: 0.9;
      position: absolute;
      right: 0.5em;
      padding: 0 0.5em;
      background: white;
      display: inline-block;
    }
    .smooth-dnd-container {
      margin: 0.5em 0;
      min-height: 5px !important;
      border-radius: 6px;
      background: repeating-linear-gradient(
        -55deg,
        #eeeeee,
        #eeeeee 10px,
        #e2e8f0 10px,
        #e2e8f0 20px
      );
    }
  }
`

export const ResourcesDetail = styled.div`
  && {
    font-size: 0.7em;
    line-height: 1;
    color: rgba(0, 0, 0, 0.6);
  }
`

export const ResourcePicker = styled.div`
  && {
    /* margin: 0em 0em 0em 0em; */

    margin: 0;
    padding: 0em 0em 0em 0em;
    border-radius: 0px;
    /* border: #F4F4F4; */
    width: 100%;
    left: 0;
    position: fixed;
    bottom: 0;
    .smooth-dnd-container {
      padding: 0em 0 0 0em;
      margin: 0 0;
    }

    .smooth-dnd-draggable-wrapper {
      background: #ffffff;

      display: inline-block !important;
      font-size: 1em;
      text-align: left;
      margin: 1em 0.5em;
      padding: 0.5em 0.5em;
      box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15);
      /* box-shadow: 2px 4px 9px 2px #ccc; */
      min-width: 140px;
    }
  }
`

export const ResourcePickerContainer = styled.div`
  && {
    background: #be1e2d;
    padding: 0.1em 0.1em;
    box-shadow: 2px 4px 9px 2px rgba(0, 0, 0, 0.16);
    border-radius: 0;
    border-top-right-radius: 0px;
    overflow: hidden;
    .smooth-dnd-container {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;

      /* The emerging W3C standard
      that is currently Firefox-only */
      scrollbar-width: thin;
      scrollbar-color: #fff #be1e2d;

      /* Works on Chrome/Edge/Safari */
      ::-webkit-scrollbar {
        width: 12px;
      }
      ::-webkit-scrollbar-track {
        background: #be1e2d;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 20px;
        border: 3px solid #be1e2d;
      }

      .smooth-dnd-draggable-wrapper {
        flex: 0 0 auto;
      }
    }
  }
`

export const ResourcePickerSearch = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    padding: 0em 0em;
    margin: 0em 0em;
  }
`

//Left side - Buttons

export const SelectMenu = styled(Select)`
  && {
    /* margin: 0em 0em;
    padding: 0em 0em 0em 0em;
  }
  .simple-select {
    display: flex;

    margin: 0em 0em;
    padding: 0em 0em 0em 0em; */
  }
`

export const SelectSearchTab = styled.div`
  && {
    /* background-color: #ccc; */
    left: 20px;
    margin: 0em 0em 0em 0em;
    /* margin-right: -1%; */
    /* padding: 0.5em 0em 0.5em 0em; */
    box-shadow: 2px 4px 9px 2px rgba(0, 0, 0, 0.06);
    /* border-radius: 3px; */
    border-top-left-radius: 3px;
    background: #fff;
    /* border: #F4F4F4; */
    .smooth-dnd-container {
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    }
  }
`

//Middle side - Buttons

export const ButtonBar = styled.div`
  && {
    padding: 0em 0.5em;
    margin: 0em 0em 0em 0em;
  }
`

export const GrouppedButtonBar = styled.div`
  && {
    padding: 0em 0.5em;
    margin: 0em 0em 0em 0em;
  }
`

export const SearchTextField = styled(TextField)`
  && {
    /* background-color: #ccc; */
    margin: 0em 0em 0em 0em;
    /* margin-right: -1%; */
    /* padding: 0.5em 0em 0.5em 0em; */
    /* border-radius: 3px; */
    background: #fff;
  }
`

//Right side - Buttons
export const StyledContainer = styled(Container)`
  && {
    min-height: 100px !important;
  }
`

export const ResourceTab = styled.div`
  && {
    background-color: #fafafa;
    color: #555;
    margin: 0em;
    padding: 0;
    box-shadow: 2px 4px 9px 2px rgba(0, 0, 0, 0.06);
    border-top-right-radius: 3px;
    border: 1px solid #fafafa;
    button {
      padding: 0.8em;
      font-weight: normal;
      border-radius: 0;
      background: none;
      border-right: 1px solid white;
      span {
        display: inline-block;
        margin-right: 5px;
      }
      &:last-child {
        border-top-right-radius: 3px;
        border-right: none;
      }
      &:hover,
      &.active {
        background: #be1e2d;
        color: #fff;
      }
    }
  }
`
