import styled from 'styled-components'

export const BoardWrapper = styled.div`
  && {
    display: block;
    margin: 40px 0 0 0;
    /* overflow: auto; */
    /* height: calc(100vh - 140px); */
  }
`
