import styled from 'styled-components'

export const DayRange = styled.div`
  && {
    background: #edf2f7;
    display: flex;
    font-size: 0.9em;
    border-radius: 6px;
    padding: 0.3em 0 0.3em 1em;
    margin: 0.2em 0;
    align-items: center;
    justify-content: space-between;
  }
`
