import React, { useState, useCallback } from 'react'
import dayjs from 'dayjs'
import { useGlobalState } from '../../store'
import { ResourceHolder, GroupCard } from './styled'
import Person from './Person'
import Resource from './Resource'
import isoWeek from 'dayjs/plugin/isoWeek'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

dayjs.extend(isoWeek)
const ResourceGroupCard = ({
  group,
  viewOptions,
  column,
  onClick,
  project,
  projectResources,
  usedProjectGroups,
  sortedProjectResources,
  onResourceCardDrop,
  pickerSource,
  projectResourcesGroup
}) => {
  const [toggleCollapse, setToggleCollapse] = useState(false)
  const [userTypes, setUserTypes] = useGlobalState('userTypes')
  const [resourceTypes, setResourceTypes] = useGlobalState('resourceTypes')
  const [allUsers, setUsers] = useGlobalState('users')

  /**
   * Render group project resources and users
   * @param {*} projectResources
   * @param {*} projectResourcesGroup
   * @param {*} column
   * @param {*} project
   */
  const projectGroup = useCallback(
    (
      projectResources,
      projectResourcesGroup,
      column,
      project,
      sortedProjectResources,
      hiddenResource,
      onClick,
      pickerSource
    ) => {
      return projectResourcesGroup.map((card, i) => {
        if (
          hiddenResource ||
          (viewOptions.showApproval && card.relStatus === 2)
        )
          return <div key={i} className={'emptyPlaceholder'} />

        if (card.cardType === 'users') {
          if (
            viewOptions.type &&
            viewOptions.type !== '' &&
            viewOptions.type !== card.role
          ) {
            return <div key={i} className={'emptyPlaceholder'} />
          }
          let willChange = false
          let hasChanged = false
          let thisCollumnWeekDay = dayjs(column.id, 'DD/MM/YYYY').isoWeekday()
          let thisCollumnLess = dayjs(column.id, 'DD/MM/YYYY').subtract(
            1,
            'days'
          )
          let thisCollumnPlus = dayjs(column.id, 'DD/MM/YYYY').add(1, 'days')
          // lets check if card start date is less than 2 days ago
          if (
            card.startDate &&
            dayjs(card.startDate).isAfter(thisCollumnLess)
          ) {
            hasChanged = true
          }
          // lets check if card end date is within 2 days
          if (
            card.endDate &&
            dayjs(card.endDate).isAfter(thisCollumnLess) &&
            dayjs(card.endDate).isBefore(thisCollumnPlus)
          ) {
            willChange = true
          }

          if (thisCollumnWeekDay === 1) {
            //monday
            // lets check if card start date is less than 2 days ago
            if (
              card.startDate &&
              dayjs(card.startDate).isAfter(
                dayjs(column.id, 'DD/MM/YYYY').subtract(4, 'days')
              )
            ) {
              hasChanged = true
            }

            // lets check if card end date is within 2 days
            if (
              card.endDate &&
              dayjs(card.endDate).isAfter(
                dayjs(column.id, 'DD/MM/YYYY').subtract(1, 'days')
              ) &&
              dayjs(card.endDate).isBefore(
                dayjs(column.id, 'DD/MM/YYYY').add(1, 'days')
              )
            ) {
              willChange = true
            }
          } else if (thisCollumnWeekDay === 5) {
            // lets check if card start date is less than 2 days ago
            if (
              card.startDate &&
              dayjs(card.startDate).isAfter(
                dayjs(column.id, 'DD/MM/YYYY').subtract(1, 'days')
              )
            ) {
              hasChanged = true
            }

            // lets check if card end date is within 2 days
            if (
              card.endDate &&
              dayjs(card.endDate).isAfter(
                dayjs(column.id, 'DD/MM/YYYY').subtract(1, 'days')
              ) &&
              dayjs(card.endDate).isBefore(
                dayjs(column.id, 'DD/MM/YYYY').add(1, 'days')
              )
            ) {
              willChange = true
            }
          }

          // props.showOnlyTransfers

          if (viewOptions.showOnlyTransfers && (!willChange && !hasChanged)) {
            return <div key={i} className={'emptyPlaceholder'} />
          }

          return (
            card && (
              <Person
                key={i}
                viewType={viewOptions.viewType}
                hasChanged={hasChanged}
                willChange={willChange}
                data={card}
                types={userTypes}
                onClick={onClick}
                approved={card.relStatus === 2 ? 'true' : 'false'}
              >
                {!pickerSource &&
                  projectResources.map((resource, ia) => {
                    // lets  now loop for user resources
                    if (
                      (viewOptions.viewType === 'global' ||
                        viewOptions.viewType === 'all' ||
                        viewOptions.viewType === 'resources') &&
                      resource.accountId === card._id
                    ) {
                      return (
                        <Resource
                          key={ia}
                          data={resource}
                          types={resourceTypes}
                          onClick={onClick}
                          approved={resource.relStatus === 2 ? 'true' : 'false'}
                        />
                      )
                    }
                  })}
                {(pickerSource === 'resources' || pickerSource === 'users') && (
                  <ResourceHolder
                    groupName="userProject"
                    onDrop={e =>
                      onResourceCardDrop(column.id, project.id, card._id, e)
                    }
                    getChildPayload={index => {
                      return {
                        ...sortedProjectResources.filter(
                          r => r.accountId === card._id
                        )[index],
                        source: 'user'
                      }
                    }}
                    dragClass="project-ghost"
                    dropClass="project-ghost-drop"
                    dropPlaceholder={{
                      animationDuration: 150,
                      showOnTop: true,
                      className: 'drop-preview'
                    }}
                    dropPlaceholderAnimationDuration={200}
                  >
                    {projectResources.map((resource, ia) => {
                      // lets  now loop for user resources
                      if (
                        (viewOptions.viewType === 'global' ||
                          viewOptions.viewType === 'all' ||
                          viewOptions.viewType === 'resources') &&
                        resource.accountId === card._id
                      ) {
                        return (
                          <Resource
                            key={ia}
                            data={resource}
                            types={resourceTypes}
                            onClick={onClick}
                            approved={
                              resource.relStatus === 2 ? 'true' : 'false'
                            }
                          />
                        )
                      }
                    })}
                  </ResourceHolder>
                )}
              </Person>
            )
          )
        } else if (
          !viewOptions.showOnlyTransfers &&
          (viewOptions.viewType === 'global' ||
            viewOptions.viewType === 'resources' ||
            viewOptions.viewType === 'all') &&
          card.cardType === 'resources' &&
          card.accountId === null &&
          !viewOptions.type
        ) {
          /// lets render project resource
          return (
            card && (
              <Resource
                key={i}
                data={card}
                types={resourceTypes}
                onClick={onClick}
                approved={card.relStatus === 2 ? 'true' : 'false'}
              />
            )
          )
        }
      })
    },
    [userTypes, resourceTypes, viewOptions]
  )

  const showGroupLabel =
    !viewOptions.type &&
    viewOptions.viewType === 'global' &&
    projectResourcesGroup[group].length > 1

  const hiddenResource =
    !showGroupLabel ||
    (toggleCollapse === group ||
      viewOptions.viewType === 'all' ||
      toggleCollapse === project.id ||
      viewOptions.viewType === 'resources' ||
      viewOptions.viewType === 'users')

  return (
    <>
      {!viewOptions.showApproval && showGroupLabel ? (
        <GroupCard
          color={
            toggleCollapse !== group
              ? (usedProjectGroups[group] && usedProjectGroups[group].color) ||
                '#ffffff'
              : ''
          }
          onClick={() =>
            setToggleCollapse(toggleCollapse === group ? null : group)
          }
        >
          <span>
            {usedProjectGroups[group] && usedProjectGroups[group].name}
          </span>
          <span>{projectResourcesGroup[group].length}</span>
        </GroupCard>
      ) : (
        <div className={'emptyPlaceholder'} />
      )}
      {projectGroup(
        projectResources,
        projectResourcesGroup[group],
        column,
        project,
        sortedProjectResources,
        !hiddenResource,
        onClick,
        pickerSource
      )}
    </>
  )
}

export default ResourceGroupCard
