import React from 'react'
import {
  ResourceCard,
  ResourceInContent,
  ResourcesTitle,
  ResourcesDetail,
  AproveController
} from './styled'
import { getGlobalState } from '../../store'
import Approve from './Approve'
import { Flex } from '@chakra-ui/core'

export default props => {
  const card = props.data
  const type = props.types ? props.types.find(t => t._id === card.type) : {}
  const authors = getGlobalState('users')
  const author = authors ? authors.find(t => t._id === card.author) : {}
  return (
    <ResourceCard approved={props.approved}>
      <ResourceInContent>
        <span
          style={{ fontSize: '.7em', background: type ? type.color : '' }}
          onClick={() =>
            props.onClick && props.onClick(props.data._id, 'resources')
          }
        >
          {card.code}
        </span>

        <div>
          <ResourcesTitle>
            <Flex style={{ justifyContent: 'space-between' }}>
              <div
                onClick={() =>
                  props.onClick && props.onClick(props.data._id, 'resources')
                }
              >
                {card.name}{' '}
              </div>
              {!props.simplified && author && (
                <div className="author" style={{ textAlign: 'right' }}>
                  {author.firstName}
                  {/* <i className={`icon-people icons`} /> */}
                </div>
              )}
            </Flex>
          </ResourcesTitle>
          {props.approved === 'false' && (
            <Approve relationId={props.data.relationId} />
          )}
          {type && (
            <ResourcesDetail className={'pickerOnly'}>
              {type.name}
            </ResourcesDetail>
          )}
        </div>
      </ResourceInContent>
    </ResourceCard>
  )
}
