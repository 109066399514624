import React, { useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import ResourcesComponent from './Resources'
import Boards from './Boards'
import { useGlobalState } from '../../store'
import { setRemoteCall } from '../../remote'
import { default as EditUser } from '../../users/Edit'
import { tableCollumns as tableUserCollumns } from '../../users/Users'
import { default as EditResource } from '../../resources/Edit'
import { tableCollumns as tableResourceCollumns } from '../../resources/Resources'
import { default as EditProject } from '../../projects/Edit'
import { tableCollumns as tableProjectCollumns } from '../../projects/Projects'

import { Week, ResourceHolder, WeekdayWrap } from './styled'

const BoardsWrapper = props => {
  const [viewOptions, setviewOptions] = useGlobalState('viewOptions')

  const [projects, setProjects] = useGlobalState('projects')
  const [resources, setResources] = useGlobalState('resources')
  const [resourceTypes, setResourceTypes] = useGlobalState('resourceTypes')
  const [allUsers, setUsers] = useGlobalState('users')
  const [userTypes, setUserTypes] = useGlobalState('userTypes')

  const [openUserModal, setOpenUserModal] = useState(false)
  const [fromColumn, setFromColumn] = useState(false)
  const [openResourceModal, setOpenResourceModal] = useState(false)
  const [openProjectModal, setOpenProjectModal] = useState(false)
  const [dayResources, setDayResources] = useState([])

  // lets filter users by state
  let users = allUsers.filter(u => u.status < 2)
  /**
   * Setup initial data
   */
  const currentDate = viewOptions.date //need to update this with controllers
  let searchDate = dayjs(currentDate)
  let items = 1
  if (viewOptions.displayType === 'week') {
    items = 6
    searchDate = dayjs(currentDate).startOf('isoWeek')
  }

  /**
   * need to reset day resrocess on view change
   */
  useEffect(() => {
    setDayResources([])
  }, [viewOptions])

  /**
   * When we click on the card we should open the correct edit modal
   * @param {*} e
   * @param {*} type
   */
  const onClick = (e, type) => {
    if (type === 'people') setOpenUserModal(users.find(u => u._id === e))
    if (type === 'project') setOpenProjectModal(projects.find(u => u._id === e))
    if (type === 'resources')
      setOpenResourceModal(resources.find(u => u._id === e))
  }

  /**
   * Set table data
   */
  const set = (data, notice, type) => {
    if (type === 'users') setRemoteCall('setUsers', data, notice)
    if (type === 'projects') setRemoteCall('setProjects', data, notice)
    if (type === 'resources') setRemoteCall('setResources', data, notice)
  }

  /**
   * On drop card back into resrouce picker we need to add it back
   * removing is done when droped into collumn to prevent car disapering into ether
   * @param {*} e
   */
  const dropResourceCard = e => {
    const { addedIndex, payload } = e
    if (!addedIndex) return null
    setRemoteCall(
      'unsetRelationToProject',
      {
        _id: payload.relationId,
        date: fromColumn
      },
      null
    )
  }

  /**
   * Render output
   */
  return (
    <>
      <Week>
        <Boards
          {...{
            onClick: onClick,
            setFromColumn: setFromColumn,
            dayResources,
            setDayResources
          }}
        />

        <ResourcesComponent
          {...{
            // resources: availableResources,
            // users: availableUsers,
            resources,
            resourceTypes: resourceTypes,
            users: users,
            userTypes: userTypes,
            dropResourceCard: dropResourceCard,
            onClick: onClick,
            dayResources: dayResources
          }}
        />
      </Week>
      {openProjectModal && (
        <EditProject
          data={openProjectModal}
          form={tableProjectCollumns}
          set={set}
          setOpenModal={setOpenProjectModal}
        />
      )}
      {openUserModal && (
        <EditUser
          data={openUserModal}
          form={tableUserCollumns}
          set={set}
          types={userTypes}
          setOpenModal={setOpenUserModal}
        />
      )}
      {openResourceModal && (
        <EditResource
          data={openResourceModal}
          form={tableResourceCollumns}
          set={set}
          types={resourceTypes}
          setOpenModal={setOpenResourceModal}
        />
      )}
    </>
  )
}

export default BoardsWrapper
