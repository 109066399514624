import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  Box,
  Button,
  InputLeftAddon,
  InputGroup,
  Select,
  Input,
  Textarea,
  Text,
  Flex,
  Heading
} from '@chakra-ui/core'
import Upload from '../components/Upload'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/core'
import { getGlobalState, useGlobalState } from '../store'
import Timeline from '../components/Timeline/Timeline'

import Map from '../components/Map/Map'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
export default function Edit(props) {
  const [details, setDetails] = useState(props.data || {})
  const [view, setView] = useState('info')
  const projects = getGlobalState('projects')
  const relations = getGlobalState('relations')

  let thisProject = {}

  const thisRel = relations.find(rel => {
    return rel.resourceId.includes(details._id)
  })
  if (thisRel)
    thisProject = projects.find(
      pr => thisRel.projectId && thisRel.projectId.includes(pr._id)
    )

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }

  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Edit {details ? details.firstName : ''} details{' '}
          <Button
            size={'sm'}
            mr={3}
            variant={view === 'info' ? 'solid' : 'outline'}
            onClick={() => setView('info')}
          >
            Info
          </Button>
          <Button
            size={'sm'}
            variant={view === 'history' ? 'solid' : 'outline'}
            mr={3}
            onClick={() => {
              setView('history')
            }}
          >
            Histórico
          </Button>
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {props.form &&
            view === 'info' &&
            props.form.map((k, i) => {
              if (k.k === '_id') return null

              if (k.t === 'upload') {
                return (
                  <Box key={i} margin={'2em 0'}>
                    {details[k.k] && (
                      <img
                        style={{
                          width: '100%',
                          height: '20vh',
                          objectFit: 'contain'
                        }}
                        src={details[k.k]}
                      />
                    )}
                    <Upload
                      key={i}
                      callback={files =>
                        handleFormFieldChange(k.k, { target: { value: files } })
                      }
                    />
                  </Box>
                )
              }
              if (k.t === 'files') {
                return (
                  <Box key={i} margin={'2em 0'}>
                    <Flex margin={'2em 0'} flexWrap={'wrap'}>
                      {details[k.k] &&
                        details[k.k].map((file, i) => {
                          let fileName = file.src.split(/\./)
                          return (
                            <div
                              key={i}
                              style={{
                                boxShadow: '0px 4px 8px -4px rgb(0 0 0 / 15%)',
                                padding: '1em',
                                borderRadius: '3px',
                                background: '#fafafa',
                                fontSize: '.9em',
                                position: 'relative',
                                margin: '0 1em 1em 0'
                              }}
                            >
                              <div
                                className="icon-minus"
                                onClick={() => {
                                  handleFormFieldChange(k.k, {
                                    target: {
                                      value: details[k.k].filter(
                                        (f, a) => a !== i
                                      )
                                    }
                                  })
                                }}
                                style={{
                                  position: 'absolute',
                                  top: '-.3em',
                                  right: '-.3em',
                                  width: '20px',
                                  height: '20px',
                                  cursor: 'pointer'
                                }}
                              />
                              <a href={file.src} target={'_blank'}>
                                {file.name || <strong>{fileName[1]}</strong>}
                                <br />
                                <span style={{ fontSize: '.7em' }}>
                                  {dayjs(file.date).format('DD-MM-YYYY')}
                                </span>
                              </a>
                            </div>
                          )
                        })}
                    </Flex>
                    <Box margin={'2em 0'}>
                      <Upload
                        key={i}
                        fullCallback={files =>
                          handleFormFieldChange(k.k, {
                            target: {
                              value: [
                                ...(details[k.k] || []),
                                {
                                  date: new Date(),
                                  src: files.link,
                                  name: files.name
                                }
                              ]
                            }
                          })
                        }
                      />
                    </Box>
                  </Box>
                )
              }

              if (k.k === 'type' && props.types)
                return (
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        t =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'status')
                return (
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      <option value={0}> Inativo </option>
                      <option value={1}> Activo </option>
                      <option value={2}> Indisponível </option>
                    </Select>
                  </InputGroup>
                )
              if (k.t === 'map') {
                return (
                  <div style={{ height: '300px' }}>
                    <Map location={thisProject.location} />
                  </div>
                )
              }
              if (k.t === 'textarea') {
                return (
                  <>
                    <InputGroup margin={'1em 0'} size="sm">
                      <Textarea
                        padding={'1.5em .5em'}
                        onChange={event => handleFormFieldChange(k.k, event)}
                        placeholder={k.l}
                        type={k.t || 'text'}
                        value={details[k.k]}
                      />
                    </InputGroup>
                  </>
                )
              }
              if (k.t === 'datetime') {
                return (
                  <>
                    <InputGroup margin={'1em 0'} size="sm">
                      <InputLeftAddon
                        minWidth={'120px'}
                        textAlign={'center'}
                        padding={'1.45em .5em'}
                        children={k.l}
                        fontWeight={'bold'}
                      />
                      <Input
                        padding={'.5em .5em'}
                        height={'3em'}
                        onChange={event => handleFormFieldChange(k.k, event)}
                        type={'date'}
                        placeholder={k.l}
                        value={dayjs(details[k.k]).format('YYYY-MM-DD')}
                      />
                    </InputGroup>
                  </>
                )
              }
              if (k.t === 'limits') {
                return (
                  <>
                    <InputGroup margin={'1em 0'} size="sm">
                      <InputLeftAddon
                        minWidth={'120px'}
                        textAlign={'center'}
                        padding={'1.45em .5em'}
                        children={k.l}
                        fontWeight={'bold'}
                      />
                      <Input
                        padding={'.5em .5em'}
                        height={'3em'}
                        onChange={event =>
                          handleFormFieldChange(k.k, {
                            target: {
                              value: {
                                ...(details[k.k] || {}),
                                spaces: event.target.value
                              }
                            }
                          })
                        }
                        type={k.t || 'text'}
                        placeholder={k.l}
                        value={
                          details[k.k] && details[k.k].spaces
                            ? details[k.k].spaces
                            : 0
                        }
                      />
                    </InputGroup>
                  </>
                )
              }
              return (
                <>
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.45em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Input
                      padding={'.5em .5em'}
                      height={'3em'}
                      onChange={event => handleFormFieldChange(k.k, event)}
                      type={k.t || 'text'}
                      placeholder={k.l}
                      value={details[k.k]}
                    />
                  </InputGroup>
                </>
              )
            })}
          {view === 'history' && (
            <Timeline type={'resource'} id={details._id} />
          )}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={() => props.setOpenModal()}>
            Cancel
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              props.set(details, ` updating resources`, 'resources')
              props.setOpenModal()
            }}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
