import React from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { setStorageState } from '../store'

const WatchAuth = props => {
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 401) {
        setStorageState('loggedIn', null)
        props.history.push('/')
      }
      return error
    }
  )
  return props.children
}
export default withRouter(WatchAuth)
