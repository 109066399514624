import React, { useState } from 'react'
import dayjs from 'dayjs'
import { InputGroup, Input, Button, Select } from '@chakra-ui/core'
import { DayRange } from './styled.js'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default function Availability(props) {
  const [details, setDetails] = useState({
    dateStart: dayjs().format('YYYY-MM-DD'),
    dateEnd: dayjs()
      .add(1, 'days')
      .format('YYYY-MM-DD'),
    timeArray: props.data || []
  })

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }

  const removeDays = i => {
    details.timeArray.splice(i, 1)
    setDetails({ ...details, timeArray: details.timeArray })
    props.setData(details.timeArray)
  }
  const addDays = () => {
    const { dateStart, dateEnd, type } = details
    if (!dateStart || !dateEnd || !type) return null
    const newTime = [...details.timeArray, [dateStart, dateEnd, type]]
    setDetails({ ...details, timeArray: newTime })
    props.setData(newTime)
  }

  return (
    <>
      <InputGroup margin={'1em 0'} size="sm">
        <Input
          padding={'.5em .5em'}
          height={'3em'}
          onChange={event => handleFormFieldChange('dateStart', event)}
          type={'date'}
          value={dayjs(details.dateStart).format('YYYY-MM-DD')}
        />
        <Input
          padding={'.5em .5em'}
          height={'3em'}
          onChange={event => handleFormFieldChange('dateEnd', event)}
          type={'date'}
          value={dayjs(details.dateEnd).format('YYYY-MM-DD')}
        />
      </InputGroup>
      <InputGroup margin={'1em 0'} size="sm">
        <Select
          height="3em"
          onChange={event => handleFormFieldChange('type', event)}
          placeholder="Select option"
        >
          <option value={'Férias'}> Férias </option>
          <option value={'Falta'}> Falta </option>
          <option value={'Baixa'}> Baixa </option>
          <option value={'Licença'}> Licença </option>
        </Select>
        <Button size={'sm'} height={'3em'} onClick={() => addDays()}>
          +
        </Button>
      </InputGroup>
      {details.timeArray &&
        details.timeArray.map((day, i) => (
          <DayRange w={'100%'}>
            <div>{day[2]}</div>
            <div>
              {dayjs(day[0], 'YYYY-MM-DD').format('DD-MM-YYYY')} &#8594;{' '}
              {dayjs(day[1], 'YYYY-MM-DD').format('DD-MM-YYYY')}
            </div>
            <div>
              <Button size={'sm'} height={'2em'} onClick={() => removeDays(i)}>
                -
              </Button>
            </div>
          </DayRange>
        ))}
    </>
  )
}
