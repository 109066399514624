import { createGlobalState } from 'react-hooks-global-state'
import dayjs from 'dayjs'
import { presistData, getPresistData } from './util'
var weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)

let thisState
//lets load user data for reload
if (typeof localStorage !== 'undefined' && localStorage.tecnoremApp) {
  thisState = JSON.parse(localStorage.tecnoremApp)
}

const isWeekend = dayjs().weekday() >= 5
let startDate = dayjs().add(1, 'days')
if (isWeekend) {
  startDate = dayjs()
    .startOf('isoWeek')
    .add(1, 'week')
}

const initialState = {
  pageClass: 'login',
  projects: [],
  projectTypes: [],
  resources: [],
  availableResources: [],
  resourceTypes: [],
  users: [],
  pickerSource: null,
  userHistory: [],
  projectHistory: [],
  resourceHistory: [],
  availableUsers: [],
  userTypes: [],
  socket: null,
  updateRelations: false, //force manual update
  remoteStated: false,
  loggedIn: false,
  relations: [],
  setLoggedIn: null,
  viewOptions: {
    date: startDate,
    displayType: 'week', // week||day
    viewType: 'global',
    showApproval: false,
    type: false,
    search: false
  },
  ...thisState
}

initialState.viewOptions = {
  ...initialState.viewOptions,
  date: startDate
}

const { useGlobalState, setGlobalState, getGlobalState } = createGlobalState(
  initialState
)

const setStorageState = (type, data) => {
  presistData('tecnoremApp', { ...thisState, [type]: data })
  setGlobalState(type, data)
}

export { useGlobalState, setGlobalState, getGlobalState, setStorageState }
